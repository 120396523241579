var CONSTANTS = (function(){
	var constants = {
		"TEMPLATES": {
			"load": {
				"loading" : {
					"title": "Bezig met laden …",
					"body": "<p class=\"loading\"><span>Bezig</span> <span>met</span> <span>laden &hellip;</span></p>"
				},
				"fail" : {
					"title": "Er liep iets mis!",
					"body": "<p>De gegevens konden <strong>niet geladen</strong> worden.</p>"
				}
			},
			"alert": {
				"fail" : {
					"title": "Er liep iets mis!"
				}
			},
			"form": {
				"fail" : {
					"prefix": "<p>Niet alle formuliervelden werden juist ingevuld. <strong>Het formulier is niet verzonden.</strong></p>",
					"suffix": ""
				}
			}
		},
		"BINDINGS": {
			"A": "href",
			"IMG": "src"
		}
	};

	return{
		get: function(index){
			if(constants[index] === undefined){
				throw new Error('Constante \'' + index + '\' ontbreekt.');
			}
			else{
				return constants[index];
			}
		}
	};
})();