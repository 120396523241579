var VARIABLES = (function(){
	var variables = {
		"WEBSITE": "dakkofferhuren",
		"PRODUCTEN": {
			"dakkoffers": {
				"url": "/dakkoffers",
				"title": {
					"singular": "Dakkoffer",
					"plural": "Dakkoffers",
				},
				"label": {
					"singular": "Dakkoffer",
					"plural": "Dakkoffers",
				},
				"tekstlabel": {
					"singular": "dakkoffer",
					"plural": "dakkoffers",
				},
				"article": "de",
				"huurtarief": {
					"parameter": "kleur"
				}
			},
			"trekhaakkoffers": {
				"url": "/trekhaakkoffers",
				"title": {
					"singular": "Trekhaakkoffer",
					"plural": "Trekhaakkoffers",
				},
				"label": {
					"singular": "Trekhaakkoffer",
					"plural": "Trekhaakkoffers",
				},
				"tekstlabel": {
					"singular": "trekhaakkoffer",
					"plural": "trekhaakkoffers",
				},
				"article": "de",
				"huurtarief": {
					"parameter": "kleur"
				}
			},
			"dakkoffers_accessoires": {
				"url": "/dakkoffers/accessoires",
				"title": {
					"singular": "Accessoire",
					"plural": "Accessoires",
				},
				"label": {
					"singular": "Accessoire",
					"plural": "Accessoires",
				},
				"tekstlabel": {
					"singular": "accessoire",
					"plural": "accessoires",
				},
				"article": "het",
				"huurtarief": "huurtarief"
			},
			"draagsystemen": {
				"url": "/draagsystemen",
				"title": {
					"singular": "Draagsysteem",
					"plural": "Draagsystemen",
				},
				"label": {
					"singular": "Draagsysteem",
					"plural": "Draagsystemen",
				},
				"tekstlabel": {
					"singular": "draagsysteem",
					"plural": "draagsystemen",
				},
				"article": "het",
				"huurtarief": {
					"parameter": "huurperiode"
				}
			},
			"skidragers": {
				"url": "/skidragers",
				"title": {
					"singular": "Skidrager",
					"plural": "Skidragers",
				},
				"label": {
					"singular": "Skidrager",
					"plural": "Skidragers",
				},
				"tekstlabel": {
					"singular": "skidrager",
					"plural": "skidragers",
				},
				"article": "de",
				"huurtarief": "huurtarief"
			},
			"golfkoffers": {
				"url": "/golfkoffers",
				"title": {
					"singular": "Golfkoffer",
					"plural": "Golfkofferss",
				},
				"label": {
					"singular": "Golfkoffer",
					"plural": "Golfkoffers",
				},
				"tekstlabel": {
					"singular": "golfkoffer",
					"plural": "golfkoffers",
				},
				"article": "de",
				"huurtarief": "huurtarief"
			}
		},
		"HOOFDPRODUCT": "dakkoffers",
		"PRODUCTPARAMETERS": {
			"dakkoffers": "montage",
			"dakkoffers_accessoires": false,
			"trekhaakkoffers": "montage",
			"draagsystemen": false,
			"skidragers": false,
			"golfkoffers": false
		},
		"LOCATIES": {
			"aanmeldpunt": {
				"label": "Aanmeldpunt",
				"lat": 51.032295,
				"long": 5.376493,
				"omschrijving": "BOSTHON Sport – Aanmeldpunt",
				"straat_nummer": "Grote Baan 61",
				"postcode": "3530",
				"plaats": "Houthalen-Helchteren"
			},
			"montagepunt": {
				"label": "Montagepunt",
				"lat": 51.033644,
				"long": 5.377519,
				"omschrijving": "BOSTHON Sport – Montagepunt",
				"straat_nummer": "Grote Baan 148",
				"postcode": "3530",
				"plaats": "Houthalen-Helchteren"
			}
		}
	};

	return{
		get: function(index){
			if(variables[index] === undefined){
				throw new Error('Variabele \'' + index + '\' ontbreekt.');
			}
			else{
				return variables[index];
			}
		}
	};
})();